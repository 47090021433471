import { observer } from 'mobx-react';
import React, { useEffect } from 'react';
import { useStore } from '../../store/hooks';
import Button from '../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import './UpgradeManagement.scss';
import Spinner from '../../components/Spinner/Spinner';
import Grid from '../../components/Grid/Grid';
import TextBox from '../../components/TextBox/TextBox';
import { runInAction } from 'mobx';
import { useCheckSinglePermission } from '../../security/security-hook';
import { PERMISSION_UPDATE, RESOURCE_GLOBAL_VALUES } from '../../types/users-types';
import SearchBar from '../../components/SearchBar/SearchBar';
import Checkbox from '../../components/Checkbox/Checkbox';

const UpgradeManagement = observer(() => {
  const { upgradeManagementStore: store } = useStore();
  const { loaders, itemsFiltered, colDefs, cancelling,
    maxUpgrades, maxUpgradesEditMode, maxUpgradesCurrent, maxUpgradesError, searchText, futureOnly,
    sorting, editItemIndex } = store;

  const navigate = useNavigate();

  useEffect(() => void store.init(), [store]);

  const editMaxUpdatesAllowed = useCheckSinglePermission(RESOURCE_GLOBAL_VALUES, PERMISSION_UPDATE);

  if (loaders.page) return <Spinner />;

  return (
    <div className="upgrade-management_wrapper">
      <div className='page-heading'>
        <div className='heading--level-1'>Upgrade Management</div>
        <div className="spot-typography__text--tertiary">
          Search and manage Cornerstone upgrades.
        </div>
      </div>
      <div className="upgrade-management_head-actions">
        <div className="align-left">
          <div className="search-bar-wrapper">
            <SearchBar
              className='skeleton'
              ariaLabel='label-search'
              initialValue={searchText}
              placeholder='Search SAP ID, Practice Name, Practice Contacts, Last Updated By'
              fontSize={13}
              disabled={loaders.refresh || loaders.maxUpgrades || cancelling}
              onSearch={v => runInAction(() => store.searchText = v)}
            />
          </div>
          <Checkbox
            id='show-future-upgrades-only'
            ariaLabel='show-future-upgrades-only'
            className='show-future-upgrades-only'
            checked={futureOnly}
            disabled={loaders.refresh || loaders.maxUpgrades || cancelling}
            onChange={c => runInAction(() => store.futureOnly = c)}
          >
            Show Future Upgrades Only
          </Checkbox>
          <span className="max-upgrades_wrapper">
            Maximum upgrades per day
            <TextBox
              className="text-box"
              ariaLabel="max-upgrades-textbox"
              value={maxUpgradesEditMode ? maxUpgradesCurrent : maxUpgrades}
              disabled={!maxUpgradesEditMode}
              error={maxUpgradesError}
              onChange={value => store.changeMaxUpgrades(value)}
              inline
            />
            {editMaxUpdatesAllowed && (
              !maxUpgradesEditMode
                ? (
                  <Button
                    type='link'
                    title='Edit'
                    icon='edit'
                    onClick={() => store.startEditingMaxUpgrades()}
                  />
                ) : <>
                  <Button
                    type='link'
                    title='Save'
                    icon='checkmark'
                    onClick={() => store.updateMaxUpgrades()}
                    disabled={!maxUpgradesCurrent}
                    loading={loaders.maxUpgrades}
                  />
                  <Button
                    type='link'
                    title='Cancel'
                    icon='cancel'
                    disabled={loaders.maxUpgrades}
                    onClick={() => store.cancelEditingMaxUpgrades()}
                  />
                </>
            )}
          </span>
        </div>
        <Button
          className='refresh-btn'
          type='primary'
          size='small'
          disabled={loaders.refresh || cancelling}
          onClick={() => store.refresh()}
        >
          Refresh List
        </Button>
      </div>
      {loaders.refresh && <Spinner size={24} />}
      <div className='upgrade-management_middle-actions'>
        <Button
          ariaLabel='add-item'
          type='link'
          size='small'
          icon='plus'
          disabled={loaders.refresh || editItemIndex > -1}
          onClick={() => store.addItem()}
        >
          New Record
        </Button>
      </div>
      <Grid
        id='upgrade-management-grid'
        columns={colDefs}
        data={itemsFiltered}
        sorting={sorting}
      />
      <div className="upgrade-management_actions">
        <Button className="back" type='link' size='small' onClick={() => navigate(-1)}>Back</Button>
        <div className='main-buttons'>
          <Button type='primary' size='small' onClick={() => navigate('/')}>Dashboard</Button>
        </div>
      </div>
    </div>
  );
});

export default UpgradeManagement;
