import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { isUpgradeScheduled, PracticeConfig } from '../../types/practice-types';
import Button from '../../components/Button/Button';
import Pill from '../../components/Pill/Pill';
import { useStore } from '../../store/hooks';
import SpotIcon from '../../components/SpotIcon/SpotIcon';
import Popover from '../../components/Popover/Popover';
import { isDefined } from '../../utils/object-utils';
import SpotIconButton from '../../components/SpotIconButton/SpotIconButton';
import TextBox from '../../components/TextBox/TextBox';
import { runInAction } from 'mobx';
import DropDown from '../../components/DropDown/DropDown';
import Spinner from '../../components/Spinner/Spinner';

interface CommonRendererProps {
  row: PracticeConfig;
  index: number;
}

export const MessageCancelUpgrade = () => <>
  <p>Are you sure you want to cancel this upgrade?</p>
  <p>There is no rollback for this operation.</p>
</>;

export const MessageCancelUpgradeError = () => <>
  <p>MyCornerstone encountered an error while cancelling the upgrade.</p>
  <p>An email has been sent to mycornerstonesupport@idexx.com and the practice contacts have been notified.</p>
  <p>Please review the summary report and Kaseya for more details.</p>
</>;

export const MessageCancelUpgradeCommunicationError = () => <>
  <p>The practice&apos;s upgrade has been cancelled, but no communications were sent.</p>
  <p>Use the Summary Report to collect contact information from the practice and notify them of the cancellation.</p>
  <p>Email mycornerstonesupport@idexx.com as well to notify the MyCornerstone team.</p>
</>;

const StatusRenderer = observer((props: CommonRendererProps) => {
  const { row } = props;
  if (!row.scheduleUpgradeStatus) return null;
  const status = (row.scheduleUpgradeStatus || '').replaceAll(/^(.*)\s+\(.*$/g, '$1');
  const caption = `${status} (${row.scheduleError === 'Kaseya' ? 'Error' : 'Success'})`;
  if (!row.scheduleError) return <Pill type="positive" icon="checkmark" small outline title="No Errors">{caption}</Pill>;
  if (row.scheduleError === 'Communication') {
    return <Pill type='warning' icon='alert-notification' small outline title='Communications to practice contacts failed but upgrade was cancelled'>{caption}</Pill>;
  }
  return <Pill type="negative" icon="cancel" small outline title="Errors, check summary report and Kaseya">{caption}</Pill>;
});

const ContactsRenderer = observer((props: CommonRendererProps) => {
  const { row } = props;
  return <>
    {(row.contacts || []).map((contact, index) => (
      <React.Fragment key={contact.email}>
        {index > 0 ? ', ' : ''}
        <span className="contact-name">{contact.name}</span>
      </React.Fragment>
    ))}
  </>;
});

const ReportRenderer = observer((props: CommonRendererProps) => {
  const { row } = props;
  if (!row.pdfUrl) return null;
  return (
    <div style={{ width: '100%', textAlign: 'center' }}>
      <a href={row.pdfUrl} target="_blank" rel="noopener noreferrer" className="doc-link">
        <img src="/images/pdf_document_icon.svg" className="doc-icon" alt="PDF icon" title="Click to view the report"/>
      </a>
    </div>
  );
});

const SapIdRenderer = observer((props: CommonRendererProps) => {
  const { row, index } = props;
  const { upgradeManagementStore: store } = useStore();
  const { editItemIndex, isNewItem } = store;

  const changeValue = (newValue: string): void => {
    if (/^\d*$/.test(newValue)) {
      runInAction(() => row.sapId = newValue);
    }
  };

  if (editItemIndex === index && isNewItem) return (
    <TextBox
      ariaLabel={`edit-text-box_sapId`}
      value={row.sapId}
      maxLength={6}
      onChange={v => changeValue(v)}
      inCell
    />
  );
  return <>{row.sapId}</>;
});

const CsVersionRenderer = observer((props: CommonRendererProps) => {
  const { row, index } = props;
  const { upgradeManagementStore: store } = useStore();
  const { editItemIndex, csVersions, currentCsVersionIndex } = store;
  const values = useMemo(() => csVersions.map(v => v.default ? `${v.version} (Default)` : v.version), [csVersions]);


  if (editItemIndex === index) return (
    <DropDown
      ariaLabel={`edit-text-box_version`}
      inCell
      values={values}
      selectedIndex={currentCsVersionIndex}
      onChange={index => runInAction(() => store.currentCsVersionIndex = index)}
    />
  );
  return <>{row.toCsVersion.version}</>;
});

const ActionsRenderer = observer((props: CommonRendererProps) => {
  const { row, index } = props;
  const { upgradeManagementStore: store } = useStore();
  const { cancellingItemIndex, editItemIndex, loaders, itemsFiltered, isNewItem, csVersions, currentCsVersionIndex } = store;
  const [popoverShown, setPopoverShown] = useState<boolean>(false);

  const canCancel = useMemo(
    () => isUpgradeScheduled(row) && isDefined(row.scheduleUpgradeTimestamp) && dayjs(row.scheduleUpgradeTimestamp).isAfter(dayjs()),
    [row]
  );

   const isSaveEnabled = () => {
     const item = itemsFiltered[index];
     if (isNewItem) return /^\d{4,6}$/.test(item.sapId);
     return currentCsVersionIndex > -1 && item.toCsVersion.version !== csVersions[currentCsVersionIndex].version;
   };

  if (editItemIndex === index) {
    return <>
      {loaders.save ? (
        <Spinner size={20} />
      ) : (
        <SpotIconButton
          path='save'
          onClick={() => store.saveItem()}
          disabled={!isSaveEnabled()}
          title='Save changes'
          cypressData={`save-${index}-btn`}
        />
      )}
      <SpotIconButton
        path='cancel'
        disabled={loaders.save}
        onClick={() => store.cancelEditing()}
        title='Cancel changes'
        cypressData={`cancel-${index}-btn`}
      />
    </>;
  }

  if (editItemIndex > -1) return null;

  return (
    <Popover
      id={`actions-popover-${index}`}
      position="bottom-left"
      inset="small"
      element={<SpotIcon ariaLabel="practice-ellipsis" path="more-2" title="Click the action menu to access practice specific options."/>}
      shown={popoverShown}
      setShown={setPopoverShown}
      cypressData={`actions-${index}-popover`}
    >
      <Button
        type="link"
        size="small"
        disabled={row.scheduleUpgradeStatus === 'Scheduled'}
        onClick={() => { store.startEdit(index); setPopoverShown(false); }}
      >
        Edit
      </Button>
      <Button
        type="link"
        size="small"
        disabled={!canCancel}
        loading={index === cancellingItemIndex}
        onClick={() => { store.promptCancelUpgrade(index); setPopoverShown(false); }}
      >
        Cancel And Notify
      </Button>
    </Popover>
    );
});

export const sapIdRenderer = (row: PracticeConfig, index: any) => <SapIdRenderer row={row} index={index} />;
export const csVersionRenderer = (row: PracticeConfig, index: any) => <CsVersionRenderer row={row} index={index} />;
export const statusRenderer = (row: PracticeConfig, index: any) => <StatusRenderer row={row} index={index} />;
export const contactsRenderer = (row: PracticeConfig, index: any) => <ContactsRenderer row={row} index={index} />;
export const reportRenderer = (row: PracticeConfig, index: any) => <ReportRenderer row={row} index={index} />;
export const actionsRenderer = (row: PracticeConfig, index: any) => <ActionsRenderer row={row} index={index} />;
export const dateRenderer = (timestamp: number | undefined) => timestamp ? dayjs(timestamp).format('MM-DD-YY HH:mm') : '' ;
