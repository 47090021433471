import React, { useEffect } from 'react';
import './ScheduleUpgrade.scss';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarWithOffset from '../../components/CalendarWithOffset/CalendarWithOffset';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import { runInAction } from 'mobx';
import SpotIcon from '../../components/SpotIcon/SpotIcon';

dayjs.extend(utc);

const ScheduleUpgrade = observer(() => {
  const { scheduleUpgradeStore: store, globalStore } = useStore();
  const { practice, sapId, saveLoading, utcOffset, dateStr, timeZoneName, continueDisabled, scheduleUpgradeTimestamp } = store;
  const { eventIntervalsHandler } = globalStore;
  const navigate = useNavigate();

  useEffect(() => {
    if (!sapId) {
      navigate('/');
      return;
    }
    store.init();
    return () => store.close();
  }, [navigate, sapId, store]);

  return (
    <div className='schedule-upgrade-wrapper'>
      <div className='heading--level-1'>Schedule Upgrade for <span title={globalStore.selectedSap.name}>{globalStore.selectedSap.id}&apos;s</span> {practice?.toCsVersion.version} upgrade</div>
      <div className='spot-typography__text--tertiary'>
        Select the date and time to start your automated upgrade.
      </div>
      <CalendarWithOffset
        type="Date"
        className='schedule-calendar'
        value={scheduleUpgradeTimestamp}
        onChange={timestamp => runInAction(() => store.scheduleUpgradeTimestamp = timestamp)}
        eventIntervalsHandler={eventIntervalsHandler}
        utcOffset={utcOffset}
        cypressData="calendar-date"
      />
      <div className="start-time-wrapper">
        <div className="start-time-label">Start Time</div>
        <CalendarWithOffset
          type="Time"
          className="time-select"
          value={scheduleUpgradeTimestamp}
          onChange={timestamp => runInAction(() => store.scheduleUpgradeTimestamp = timestamp)}
          utcOffset={utcOffset}
          eventIntervalsHandler={eventIntervalsHandler}
          cypressData="calendar-time"
        />
        <span className="start-time-timezone">
          {timeZoneName}
          {!practice?.timeZoneName && <SpotIcon className="info-icon" path="info-2" title="The current time zone displayed on the web page reflects the last checked UTC offset and updates if any changes have occurred." />}
        </span>
      </div>
      <p>Scheduled</p>
      <p className='show-date' role="show-date">{dateStr} {timeZoneName}</p>
      {practice && !practice.isLocked && (
        <div className="disclaimer">
          <p><SpotIcon className="note-icon" path="alert-notification" title="expand" />Note</p>
          <p>Clicking <span className='bold'>Continue</span> will give you exclusive access to the upgrade task to prevent concurrent scheduling.</p>
          <p>We will prompt you at the end of <span className='bold'>90 minutes</span>, and you can extend your session if needed.</p>
        </div>
      )}
      <div className='schedule-upgrade_actions'>
        <Button type='link' size='small' onClick={() => globalStore.showExitUpgradePopup(() => store.exitUpgrade())} cypressData="exit-upgrade-btn">Exit Upgrade</Button>
        <div className='main-buttons'>
          <Button className="schedule-btn" type='primary' size='small' disabled={continueDisabled} loading={saveLoading} onClick={() => store.confirmSchedule()} cypressData="continue-btn">
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
});

export default ScheduleUpgrade;
