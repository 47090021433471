import { useMemo } from 'react';
import { observer } from 'mobx-react';
import { ResourcePermission } from '../types/users-types';
import { useStore } from '../store/hooks';
import { arrayToMap } from '../utils/object-utils';

export interface HasPermissionsProps {
  permissions: ResourcePermission[];
  children: any;
}

export const checkIfAnyPermissionAllowed = (allowedPermissions: ResourcePermission[] | undefined, permissionsToCheck: ResourcePermission[] | undefined): boolean => {
  if (!allowedPermissions || !permissionsToCheck) return false;
  return allowedPermissions
    .some(ap => permissionsToCheck.filter(rpToCheck => ap.resourceId === rpToCheck.resourceId)
      .some(rpToCheck => ap.permissions.map(p => p.id)
        .some(p => rpToCheck.permissions.map(p => p.id).includes(p))));
};

export const checkSinglePermission = (allowedPermissions: ResourcePermission[], resourceId: string, permissionId: string): boolean => {
  return checkIfAnyPermissionAllowed(allowedPermissions, [{ resourceId, permissions: [{ id: permissionId }] }]);
};

export const useCheckPermissions = (permissions: ResourcePermission[]): boolean => {
  const userPermissions = useStore().apiStore.userPermissions;
  return useMemo(() => checkIfAnyPermissionAllowed(userPermissions, permissions), [userPermissions, permissions]);
};

export const useCheckSinglePermission = (resourceId: string, permissionId: string): boolean => {
  return useCheckPermissions([{ resourceId , permissions: [{ id: permissionId }] }]);
};

export const useCheckSingleSapPermission = (sapIds: string[], resourceId: string, permissionId: string): Record<string, boolean> => {
  const { userSapPermissions, userGlobalPermissions } = useStore().apiStore;
  return useMemo(() => {
    const permissionMap = arrayToMap(userSapPermissions, 'sapId');
    const value: Record<string, boolean> = {};
    const permissionToCheck: ResourcePermission = { resourceId, permissions: [{ id: permissionId }]};
    const globalAllow = checkIfAnyPermissionAllowed(userGlobalPermissions, [permissionToCheck]);
    sapIds.forEach(sapId => {
      value[sapId] = globalAllow || checkIfAnyPermissionAllowed(permissionMap[sapId]?.resourcePermissions, [permissionToCheck]);
    });
    return value;
  }, [permissionId, resourceId, sapIds, userGlobalPermissions, userSapPermissions]);
};

const HasPermissions = observer((props: HasPermissionsProps) => (useCheckPermissions(props.permissions) ? props.children : null));

export default HasPermissions;
