import { action, computed, observable, runInAction } from 'mobx';
import { isDefined, isEmailAddress } from '../../utils/object-utils';
import dayjs from 'dayjs';
import { timeZoneNameFromOffset } from '../../utils/date-utils';
import { GridColDef } from '../../components/Grid/Grid';
import { actionsRenderer, MessageChosenTimeUnavailable, rowCellRenderer } from './renderers';
import type { Contact, PracticeConfig, Statistics } from '../../types/practice-types';
import { isAgentUpgradeQualified } from '../../types/practice-types';
import type { ReactElement } from 'react';
import React from 'react';
import { PRACTICE_PATH } from '../../store/constants';
import AbstractUpgradeTaskStore from '../../store/AbstractUpgradeTaskStore';

const emptyContact = (): Contact => ({ name: '', phone: '', email: '' });
const isEmptyContact = (contact: Contact): boolean => Object.values(contact).every(v => !v);
const equalContacts = (contact1: Contact, contact2: Contact): boolean => Object.keys(contact1).every(key => contact1[key] === contact2[key]);

export default class ConfirmationStore extends AbstractUpgradeTaskStore {
  @observable buttonsDisabled = false;

  @observable notificationLoader = false;

  @observable cancelLoader = false;

  @observable saveLoader = false;

  @observable currentIndex: number | undefined = undefined;

  @observable currentContact: Contact = emptyContact();

  @observable contactError: Contact = emptyContact();

  @computed
  public get contactsChanged(): boolean {
    return this.root.globalStore.contactsChanged;
  }

  public set contactsChanged(value: boolean) {
    this.root.globalStore.contactsChanged = value;
  }

  @computed
  public get practice(): PracticeConfig {
    // @ts-ignore
    return this.root.globalStore.practice || {};
  }

  private set practice(value: PracticeConfig) {
    this.root.globalStore.practice = value;
  }

  @computed
  public get scheduleDate(): string {
    return this.formatTimestamp(this.practice.scheduleUpgradeTimestamp);
  }

  @computed
  public get acknowledgementsLastAcceptedDate(): string {
    return this.formatTimestamp(this.practice.acknowledgementsLastAccepted?.timestamp);
  }

  @computed
  public get colDefs(): GridColDef[] {
    return [
      { headerName: 'First/Last Name', field: 'name', renderer: rowCellRenderer('name') },
      { headerName: 'Email', field: 'email', renderer: rowCellRenderer('email') },
      { headerName: 'Phone (mobile)', field: 'phone', renderer: rowCellRenderer('phone') },
      { headerName: 'Actions', field: 'actions', renderer: actionsRenderer },
    ];
  }

  @computed
  public get currentContacts(): Contact[] {
    return this.practice.contacts || [];
  }

  @computed
  public get statistics(): Statistics {
    // @ts-ignore
    return this.practice.statistics || {};
  }

  @computed
  public get operationSuccessful(): boolean {
    return this.practice.scheduleError !== 'Kaseya';
  }

  @action
  public init(): void {
    super.init();
    this.root.globalStore.globalModalProps.show = false;
    this.currentIndex = undefined;
    if (!this.sapId || !this.practice.scheduleUpgradeTimestamp || !isDefined(this.practice.utcOffset)) {
      this.navigate('/');
      return;
    }
    if (!this.root.globalStore.upgradeScheduled) {
      const agents = this.root.globalStore.agentsData;
      const upgradeAgents = agents.filter(a => isAgentUpgradeQualified(a));
      this.practice.statistics = {
        upgradeServers: upgradeAgents.filter(row => row.type === 'Server').length,
        upgradeWorkstations: upgradeAgents.filter(row => row.type !== 'Server').length,
        doNotUpgrade: agents.length - upgradeAgents.length,
      };
      this.practice.scheduledBy = this.root.apiStore.userName;
      const currentUserContact = {
        name: this.root.apiStore.userName,
        email: this.root.apiStore.userEmail,
        phone: this.root.apiStore.user?.phone || '',
      };
      if (!this.practice.contacts || this.practice.contacts.length === 0) {
        this.practice.contacts = [currentUserContact];
      } else {
        this.practice.contacts[0] = currentUserContact;
      }
    }
  }

  @action
  public addContact(): void {
    if (this.currentIndex !== undefined) return;
    this.practice.contacts = [...this.practice.contacts || [], emptyContact()];
    this.currentContact = emptyContact();
    this.contactError = emptyContact();
    this.currentIndex = this.practice.contacts.length - 1;
  }

  @action
  public editContact(index: number): void {
    if (this.currentIndex !== undefined) return;
    if (index === 0 && this.currentContacts[0].email !== this.root.apiStore.userEmail) return;
    this.currentContact = { ...this.currentContacts[index] };
    this.contactError = emptyContact();
    this.currentIndex = index;
  }

  @action
  public validateAndUpdateContact(): void {
    if (this.currentIndex === undefined) return;
    if (isEmptyContact(this.currentContact)) {
      this.cancelEditingContact();
      return;
    }
    const { name, phone, email } = this.currentContact;
    this.contactError = emptyContact();
    if (!name) {
      this.contactError.name = 'First/Last name is required';
    }
    if (!phone && !email) {
      this.contactError.phone = 'Email or phone is required';
      this.contactError.email = 'Email or phone is required';
    }
    if (email && !isEmailAddress(email)) {
      this.contactError.email = 'Invalid email format';
    }
    if (phone && !/^\d{10}$/.test(phone)) {
      this.contactError.phone = 'Invalid phone format';
    }
    if (isEmptyContact(this.contactError)) {
      if (!equalContacts(this.currentContact, this.currentContacts[this.currentIndex])) {
        this.contactsChanged = true;
        this.currentContacts[this.currentIndex] = this.currentContact;
      }
      this.currentIndex = undefined;
    }
  }

  @action
  public cancelEditingContact(): void {
    if (this.currentIndex === undefined) return;
    if (isEmptyContact(this.currentContacts[this.currentIndex])) {
      this.deleteContact(this.currentIndex);
    } else {
      this.currentIndex = undefined;
    }
  }

  @action
  public deleteContact(index: number): void {
    this.practice.contacts = this.currentContacts.filter((_v, cIndex) => cIndex !== index);
    this.currentIndex = undefined;
  }

  @action
  public async saveForLater(): Promise<void> {
    const success = await this.doSaveForLater();
    if (success) this.navigate('/');
  }

  @action
  public async scheduleAndNotify(): Promise<void> {
    await this.wrapLoaders(async () => {
      try {
        if (!await this.verifyUpgradeTimestampAvailable()) return false;
        await this.practiceApiCallWithUpdate({
          action: 'SCHEDULE_UPGRADE',
          sapId: this.sapId,
          statistics: this.practice.statistics,
          contacts: this.practice.contacts,
          skipOfflineWorkstations: this.practice.skipOfflineWorkstations,
        });
        void this.audit('UPGRADE_TASK', 'CONFIRMATION_PAGE_SCHEDULE_AND_NOTIFY');
        runInAction(() => {
          this.contactsChanged = false;
        });
        return true;
      } catch (err: any) {
        this.showConnectionErrorPopup();
        return false;
      }
    }, loading => this.notificationLoader = loading);
  }

  @action
  public promptRescheduleUpgrade(bodyElement: ReactElement): void {
    this.root.globalStore.showOkCancelPopup(
      'Reschedule Upgrade',
      bodyElement,
      'Reschedule Upgrade',
      'Keep Upgrade',
      () => this.rescheduleUpgrade(),
      undefined,
      true
    );
  }

  @action
  public promptCancelUpgrade(): void {
    this.root.globalStore.showOkCancelPopup(
      'Cancel Upgrade?',
      'Are you sure you want to cancel your Upgrade?',
      'Cancel Upgrade',
      'Keep Upgrade',
      () => this.cancelUpgrade(),
      undefined,
      true
    );
  }

  @action
  public async rescheduleUpgrade(): Promise<void> {
    void this.audit('UPGRADE_TASK', 'SUMMARY_PAGE_RESCHEDULE_UPGRADE');
    await this.wrapLoaders(async () => {
      try {
        await this.practiceApiCallWithUpdate({
          action: 'RESCHEDULE_UPGRADE',
          sapId: this.sapId,
        });
        if (this.operationSuccessful) {
          await this.root.apiStore.fetchCurrentUserDetails();
          this.navigate('/check-qualifications');
        }
        return true;
      } catch (err: any) {
        this.showConnectionErrorPopup();
        return false;
      }
    }, loading => this.notificationLoader = loading);
  }

  @action
  public async cancelUpgrade(): Promise<void> {
    void this.audit('UPGRADE_TASK', 'SUMMARY_PAGE_CANCEL_UPGRADE');
    await this.wrapLoaders(async () => {
      try {
        await this.practiceApiCallWithUpdate({
          action: 'CANCEL_UPGRADE',
          sapId: this.sapId,
        });
        if (this.operationSuccessful) {
          await this.root.apiStore.fetchCurrentUserDetails();
          this.navigate('/');
        }
        return true;
      } catch (err: any) {
        this.showConnectionErrorPopup();
        return false;
      }
    }, loading => this.cancelLoader = loading);
  }

  @action
  public async resendNotifications(): Promise<void> {
    void this.audit('UPGRADE_TASK', 'SUMMARY_PAGE_RESEND_NOTIFICATIONS');
    await this.wrapLoaders(async () => {
      try {
        await this.practiceApiCallWithUpdate({
          action: 'RESEND_NOTIFICATIONS',
          sapId: this.sapId,
          contacts: this.practice.contacts,
        });
        runInAction(() => this.contactsChanged = false);
        return true;
      } catch (err: any) {
        this.showConnectionErrorPopup();
        return false;
      }
    }, loading => this.notificationLoader = loading);
  }

  @action
  public async exitUpgrade(): Promise<void> {
    void this.audit('UPGRADE_TASK', 'CONFIRMATION_PAGE_EXIT_UPGRADE');
    this.navigate('/');
  }

  private formatTimestamp(ts: number | undefined): string {
    if (!isDefined(ts)) return '';
    return `${dayjs(ts).utcOffset(this.practice.utcOffset || 0).format('D-MMM YYYY[, at ]h:mm a')} (${this.practice.timeZoneName || timeZoneNameFromOffset(this.practice.utcOffset)})`;
  }

  private async wrapLoaders(fn: () => Promise<boolean>, additional?: (loading: boolean) => void): Promise<boolean> {
    runInAction(() => {
      this.buttonsDisabled = true;
      additional && additional(true);
    });
    const result = await fn();
    runInAction(() => {
      this.buttonsDisabled = false;
      additional && additional(false);
    });
    return result;
  }

  private showConnectionErrorPopup(): void {
    this.root.globalStore.showOkPopup(
      'Connection error',
      'Unable to connect to your computer(s), please try again later',
    );
  }

  private async practiceApiCallWithUpdate(body: any): Promise<void> {
    const practice = await this.root.apiStore.backendPost(PRACTICE_PATH, body);
    runInAction(() => this.practice = practice);
  }

  private doSaveForLater(): Promise<boolean> {
    void this.audit('UPGRADE_TASK', 'CONFIRMATION_PAGE_SAVE_FOR_LATER');
    return this.wrapLoaders(async () => {
      try {
        await this.practiceApiCallWithUpdate({
          action: 'UPDATE_CONTACTS',
          sapId: this.sapId,
          contacts: this.practice.contacts,
        });
        return true;
      } catch (err: any) {
        alert(err.toString());
        return false;
      }
    }, loading => this.saveLoader = loading);
  }

  private async verifyUpgradeTimestampAvailable(): Promise<boolean> {
    const handler = await this.root.globalStore.fetchEventIntervals();
    if (!handler) {
      this.root.globalStore.showOkPopup(
        'Connection error',
        'Internal server error',
      );
      return false;
    }
    if (handler.nextAvailable(0) === false) {
      this.root.globalStore.showOkPopup(
        'No Upgrade Times Available',
        'There are no upgrade slots available within the next 15 days. Please try again later',
        () => {
          this.sapIdChanged = true;
          runInAction(() => this.root.scheduleUpgradeStore.showInitWarnings = false);
          this.navigate('/schedule-upgrade');
        },
      );
      return false;
    }
    if (!this.practice.scheduleUpgradeTimestamp || handler.inEventIntervals(this.practice.scheduleUpgradeTimestamp)) {
      this.root.globalStore.showOkPopup(
        'Chosen Time Unavailable',
        React.createElement(MessageChosenTimeUnavailable),
        async () => {
          await this.doSaveForLater();
          this.sapIdChanged = true;
          this.navigate('/schedule-upgrade');
        },
      );
      return false;
    }
    return true;
  }
}
