import { v4 as uuidv4 } from 'uuid';
import { action, computed, observable, runInAction } from 'mobx';
import RootStore from './RootStore';
import { storageService } from '../services/storage.service';
import { MyIDEXXUserType } from '../types/brm-types';
import { backendUrl } from '../config/app';
import AbstractStore from './AbstractStore';
import {
  EventApi,
  ResourcePermission,
  ResourcesAndPermissions,
  RoleApi,
  RoleDefinition,
  SapResourcePermission,
  UserApi,
} from '../types/users-types';
import { getPathWithQuery } from '../utils/url-utils';
import { GlobalKeys, KeyValueType, ResultObject } from '../types/common-types';
import { AgentData, AgentsKaseyaInfo, AgentStatus, CsVersion, PracticeConfig } from '../types/practice-types';
import { AGENTS_PATH, CS_VERSIONS_PATH, EVENTS_PATH, GLOBALS_PATH, PRACTICE_PATH, ROLES_PATH, USERS_PATH, VELLO_PATH } from './constants';
import { LogoutOptions } from '@auth0/auth0-spa-js';
import { VelloEnrollmentAndPracticeAuthUrlResponse } from '../types/vello-types';
import { isDefined } from '../utils/object-utils';

const USER_EMAIL = 'USER_EMAIL';

export default class ApiStore extends AbstractStore {
  @observable private _isAuthenticated = false;

  @observable user: UserApi | undefined;

  @observable userEmail: string;

  public auditRequestPromise: Promise<any> = Promise.resolve();

  public logout?: (options?: LogoutOptions) => void;

  private getAccessTokenSilently?: (...args: any) => Promise<string>;

  private readonly backEndUrl: string;

  private userGuid = uuidv4();


  public constructor(rootStore: RootStore) {
    super(rootStore);
    this.userEmail = storageService.getItem(USER_EMAIL, true);
    this.backEndUrl = backendUrl();
  }

  @computed
  public get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  @action
  public authenticate(getAccessTokenSilently: (...args: any) => Promise<string>, logout: (options?: LogoutOptions) => void, user: MyIDEXXUserType | undefined): void {
    this.getAccessTokenSilently = getAccessTokenSilently;
    this.logout = logout;
    this._isAuthenticated = true;
    this.userEmail = user?.email || '';
    // setting userId for WalkMe
    (window as any).userId = user?.sub;
    storageService.replace(USER_EMAIL, this.userEmail, true);
  }

  @computed
  public get userName(): string {
    return `${this.user?.firstName} ${this.user?.lastName}`;
  }

  @computed
  public get userPermissions(): ResourcePermission[] {
    return ([] as ResourcePermission[]).concat(
      ...(this.user?.globalResourcePermissions || []),
      ...(this.sapId ? this.user?.sapResourcePermissions?.find(srp => srp.sapId == this.sapId)?.resourcePermissions || [] : [])
    );
  }

  @computed
  public get userGlobalPermissions(): ResourcePermission[] {
    return this.user?.globalResourcePermissions || [];
  }

  @computed
  public get userSapPermissions(): SapResourcePermission[] {
    return this.user?.sapResourcePermissions || [];
  }

  @computed
  public get userGlobalRoleLowestLevel(): number {
    return Math.min(...(this.user?.globalRoles?.map(r => r.level) || []));
  }

  public userRoleLowestLevel(sapId: string): number {
    const sapRoles = this.user?.sapRoles?.find(r => r.sapId === sapId);
    if (!isDefined(sapRoles)) return this.userGlobalRoleLowestLevel;
    return Math.min(...sapRoles.roles.map(r => r.level), this.userGlobalRoleLowestLevel);
  }

  // *** agents

  public getCachedAgents(sapId: string): Promise<AgentData[]> {
    return this.callBackendApi<AgentData[]>(getPathWithQuery(AGENTS_PATH, { sapId, refresh: false }));
  }

  public getRefreshedAgents(sapId: string): Promise<AgentData[]> {
    return this.callBackendApi<AgentData[]>(getPathWithQuery(AGENTS_PATH, { sapId, refresh: true }));
  }

  public getAgentsRefreshStatus(sapId: string, lastAttempt: boolean): Promise<AgentData[]> {
    return this.callBackendApi<AgentData[]>(getPathWithQuery(AGENTS_PATH, { sapId, refresh: true, allow_update_audit_status: lastAttempt }));
  }

  public getAgent(sapId: string, agentId: string): Promise<AgentData> {
    return this.callBackendApi<AgentData>(getPathWithQuery(AGENTS_PATH, { sapId, agentId }));
  }

  public async getAgentUpgradeState(sapId: string, agentId: string): Promise<AgentStatus> {
    const wrapped = await this.callBackendApi<ResultObject<AgentStatus>>(getPathWithQuery(AGENTS_PATH, { sapId, agentId, check_upgrade_state: true }));
    return wrapped.result;
  }

  public async getAgentsKaseyaInfo(sapId: string, sendEmail?: boolean): Promise<AgentsKaseyaInfo> {
    return this.callBackendApi<AgentsKaseyaInfo>(getPathWithQuery(AGENTS_PATH, { sapId, get_kaseya_info: true, send_email: sendEmail }));
  }

  // *** practice

  public getPractice(sapId: string, initialCheck?: boolean): Promise<PracticeConfig> {
    return this.callBackendApi<PracticeConfig>(getPathWithQuery(PRACTICE_PATH, { sapId, initial_check: initialCheck }));
  }

  public getAllPractices(): Promise<PracticeConfig[]> {
    return this.callBackendApi<PracticeConfig[]>(getPathWithQuery(PRACTICE_PATH, { all: true }));
  }

  public updatePracticeAdminVerificationCode(sapId: string): Promise<any> {
    return this.backendPost(PRACTICE_PATH, {
      action: 'UPDATE_VERIFICATION_CODE',
      sapId,
    });
  }

  public async submitPracticeAdminVerificationCode(sapId: string, verificationCode: string): Promise<boolean> {
    const wrapped = await this.backendPost(PRACTICE_PATH, {
      action: 'SUBMIT_VERIFICATION_CODE',
      sapId,
      verificationCode,
    });
    return wrapped.result;
  }

  public sendInstallationInstructions(sapId: string): Promise<any> {
    return this.backendPost(PRACTICE_PATH, {
      action: 'SEND_INSTALLATION_INSTRUCTIONS',
      sapId,
    });
  }

  // *** users

  @action
  public async fetchCurrentUserDetails(): Promise<void> {
    this.userGuid = uuidv4();
    try {
      const users = await this.callBackendApi<UserApi[]>(USERS_PATH);
      if (users) {
        runInAction(() => this.user = users[0]);
      }
    } catch (e: any) {
      console.error(e);
      if (e instanceof TypeError) {
        this.root.globalStore.showOkPopup(
          'Connection error',
          'We\'re unable to establish a connection. Please check your internet connection and attempt to log in again.'
        );
      } else {
        this.root.globalStore.showOkPopup(
          'Internal server error',
          'An unexpected error occurred.  Please try logging in again. If this issue persists, please contact support.'
        );
      }
    }
  }

  public findUsersForSap(sapId: string): Promise<UserApi[]> {
    return this.callBackendApi<UserApi[]>(getPathWithQuery(USERS_PATH, { sapId }));
  }

  public findUsersByLowestRoleLevel(roleLevel?: number): Promise<UserApi[]> {
    return this.callBackendApi<UserApi[]>(getPathWithQuery(USERS_PATH, { roleLevel }));
  }

  public async checkSapUsersForResourcePermission(sapId: string, resource: string, permission: string): Promise<boolean> {
    const wrapped = await this.callBackendApi<ResultObject<boolean>>(getPathWithQuery(USERS_PATH, { sapId, resource, permission }));
    return wrapped.result;
  }

  // *** roles

  public findAllRoles(): Promise<RoleDefinition[]> {
    return this.callBackendApi<RoleDefinition[]>(ROLES_PATH);
  }

  public findRoleByGlobalAndLowestLevel(global: boolean, level?: number): Promise<RoleApi[]> {
    return this.callBackendApi<RoleApi[]>(getPathWithQuery(ROLES_PATH, { global, level }));
  }

  public findRoleByName(name: string): Promise<RoleDefinition> {
    return this.callBackendApi<RoleDefinition>(getPathWithQuery(ROLES_PATH, { name }));
  }

  public findRolesByGlobalFlag(global: boolean): Promise<RoleApi[]> {
    return this.callBackendApi<RoleApi[]>(getPathWithQuery(ROLES_PATH, { global }));
  }

  public async findAllResourcesAndPermissions(): Promise<ResourcesAndPermissions> {
    return this.callBackendApi<ResourcesAndPermissions>(getPathWithQuery(ROLES_PATH, { resources_and_permissions: true }));
  }

  // *** events

  public findAllServiceEvents(): Promise<EventApi[]> {
    return this.callBackendApi<EventApi[]>(EVENTS_PATH);
  }

  public findScheduledMaintenancesBetweenDates(startDateTime: number, endDateTime: number): Promise<EventApi[]> {
    return this.callBackendApi<EventApi[]>(getPathWithQuery(EVENTS_PATH, { startDateTime, endDateTime }));
  }

  // *** global values

  public async getGlobalValue<T = string | number | boolean>(key: typeof GlobalKeys[number]): Promise<T | null> {
    const pair = await this.callBackendApi<KeyValueType<T>>(getPathWithQuery(GLOBALS_PATH, { key }));
    return pair.value;
  }

  // *** Vello

  public getVelloEnrollmentAndPracticeAuthUrl(sapId: string): Promise<VelloEnrollmentAndPracticeAuthUrlResponse> {
    return this.backendPost<VelloEnrollmentAndPracticeAuthUrlResponse>(VELLO_PATH, {
      action: 'GET_PRACTICE_AUTH_URL',
      sapId,
    });
  }

  // *** CsVersions

  public getCsVersions(): Promise<CsVersion[]> {
    return this.callBackendApi<CsVersion[]>(getPathWithQuery(CS_VERSIONS_PATH));
  }

  @action
  public async callBackendApi<T = any>(endpoint: string, options: RequestInit = {}): Promise<T> {
    const { headers, ...others } = options;
    if (!this.getAccessTokenSilently) {
      throw new Error(`Auth0 getAccessTokenSilently() method is not set`);
    }
    const accessToken = await this.getAccessTokenSilently();
    const res = await fetch(`${this.backEndUrl}${endpoint}`, {
      ...others,
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`,
        'x-user-guid': this.userGuid,
      },
    });
    const data = await res.text();
    if (res.status !== 200) {
      throw new Error(`Http error ${res.status}: ${data}`);
    }
    return JSON.parse(data);
  }

  @action.bound
  public async backendPost<T = any>(endpoint: string, body: any): Promise<T> {
    return this.callBackendApi<T>(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
  }

  @action
  public signOut(): void {
    this.user = undefined;
    this._isAuthenticated = false;
    this.getAccessTokenSilently = undefined;
    if (this.logout) {
      this.logout({ returnTo: location.origin });
    }
  }
}
