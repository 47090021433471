import { observer } from 'mobx-react';
import Button from '../../components/Button/Button';
import React, { useEffect } from 'react';
import { useStore } from '../../store/hooks';
import './Acknowledgments.scss';
import { useNavigate } from 'react-router-dom';
import Checkbox from '../../components/Checkbox/Checkbox';

const Acknowledgments = observer(() => {
  const { acknowledgmentsStore: store, globalStore } = useStore();
  const navigate = useNavigate();
  const { loaders, ackStepIndex } = store;
  const { practice } = globalStore;

  useEffect(() => {
    store.init();
  }, [store]);

  const ackSteps = [
    <>
      <div className='ack-header'>Staff coordination</div>
      <ul>
        <li>Inform all staff members of the plan to keep workstations on and Cornerstone closed during the upgrade.</li>
        <li>Remote access to Cornerstone will be suspended during the upgrade process.</li>
      </ul>
    </>,
    <>
      <div className='ack-header'>Confirm Cornerstone exclusive use</div>
      <ul>
        <li>Cornerstone must be closed on all workstations.</li>
      </ul>
    </>,
    <>
      <div className='ack-header'>Power &amp; Connectivity</div>
      <ul>
        <li>All workstations and laptops must be plugged in and powered on while the upgrade service is transferring files.</li>
        <li>Set Power &amp; Sleep settings to “Never” to prevent workstations from going to sleep.</li>
      </ul>
      Please note: if your practice uses Cornerstone Mobile Computing, ensure all mobile workstations have been synchronized and rejoined to the network
      (Tools &gt; Mobile Computing &gt; Undo Mobile Computer). Please reference Tools &gt; Mobile Computing &gt; Mobile Computer List to ensure all mobile
      workstations have rejoined the network.
    </>,
    <>
      <div className='ack-header'>Security Settings</div>
      <ul>
        <li>
          If you have third-party antivirus or advanced firewall settings, it must be configured to allow Cornerstone Software and dbserv16.exe
          to make changes. Please see the <a className={ackStepIndex < 4 ? 'disabled-link' : ''} href='https://www.idexx.com/files/cornerstone-hw-os-guidelines.pdf' target='_blank' rel='noopener noreferrer'>Hardware &amp; System guidelines</a> for more information.
        </li>
      </ul>
      Please note: IDEXX-approved Trend Micro antivirus software is preconfigured for MyCornerstone Upgrade Service. No additional action is needed.
    </>,
    <>
      <div className='ack-header'>Consider times of automated computer tasks when scheduling</div>
      <ul>
        <li>Backups cannot be performed while the upgrade is in progress, and may interfere with the upgrade.</li>
        <li>Choose a time for your upgrade at least several hours before any backups or preferably after they would be completed.</li>
        <li>If possible, pause all third-party data collection services (e.g. Demandforce, PetDesk, Weave, Vetdata, etc.) during the scheduled upgrade time.</li>
      </ul>
    </>,
    <>
      <div className='ack-header'>Backup procedures</div>
      <ul>
        <li>It is highly recommended that a manual backup be performed after the close of business and prior to the scheduled upgrade time.</li>
      </ul>
      Please note: Cornerstone unattended end of period processes will not complete if scheduled during the upgrade service.
    </>,
    <>
      <div className='ack-header'>Windows</div>
      <ul>
        <li>Install windows updates on the server and all workstations prior to your scheduled upgrade time.</li>
      </ul>
    </>,
  ];

  return (
    <div className='acknowledgment-wrapper'>
      <div className='heading--level-1'>Before you begin your {practice?.toCsVersion.version} upgrade</div>
      <div className='spot-typography__text--tertiary'>
        Please review and check the following important steps required for a successful implementation of the MyCornerstone Upgrade Service.<br/>
        These acknowledgements will be emailed to you following Step 4: Confirmation.
      </div>
      {ackSteps.map((child, index) => {
        const key = `ack-step-${index}`;
        return (
          <Checkbox
            key={key}
            id={key}
            className='ack-step'
            checked={ackStepIndex >= index}
            disabled={ackStepIndex < index - 1}
            noFocusBorder
            ariaLabel={key}
            cypressData={key}
            onChange={checked => store.acceptStep(index, checked)}
          >
            {child}
          </Checkbox>
        );
      })}

      <div className="acknowledgment_actions">
        <Button type="link" size="small" onClick={() => navigate(-1)} cypressData="back-btn">Back</Button>
        <div className="main-buttons">
          <Button type="secondary" size="small" onClick={() => navigate("/")} cypressData="dashboard-btn">Dashboard</Button>
          <Button
            type="primary"
            size="small"
            icon="checkmark"
            disabled={ackStepIndex < ackSteps.length - 1}
            loading={loaders.accept}
            onClick={() => store.acceptAcknowledgements()}
            cypressData="continue-btn"
          >
            I acknowledge
          </Button>
        </div>
      </div>
    </div>
  );
});

export default Acknowledgments;
