import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import React, { useCallback, useEffect } from 'react';
import './Confirmation.scss';
import QualificationIcon from '../../components/QualificationIcon/QualificationIcon';
import { useNavigate } from 'react-router-dom';
import { useBlocker, useStore } from '../../store/hooks';
import Button from '../../components/Button/Button';
import Grid from '../../components/Grid/Grid';
// eslint-disable-next-line import/named
import { Action, Transition } from 'history';
import RadioButton from '../../components/RadioButton/RadioButton';
import { isDefined } from '../../utils/object-utils';

const RescheduleUpgradePrompt = () => (
  <>
    Plans change, we get it. Click <strong>Reschedule Upgrade</strong> to complete rescheduling steps.
    Your original details are retained if you click <strong>Keep Upgrade</strong>
  </>
);

const Confirmation = observer(() => {
  const { confirmationStore: store, globalStore } = useStore();
  const { scheduleDate, statistics, currentContacts, colDefs,  buttonsDisabled, notificationLoader,
    cancelLoader, saveLoader, practice, operationSuccessful, acknowledgementsLastAcceptedDate } = store;
  const { upgradeScheduled } = globalStore;
  const navigate = useNavigate();

  useEffect(() => {
    store.init();
    return () => store.close();
  }, [navigate, store]);

  useBlocker(useCallback((tx: Transition): void => {
    if (tx.action !== Action.Pop || !upgradeScheduled) tx.retry();
  }, [upgradeScheduled]));

  return (
    <div className="confirmation-wrapper">
      <div className="page-heading skeleton">
        <div className="heading--level-1">Confirmation - {upgradeScheduled ? 'Summary' : 'Schedule'} for <span title={globalStore.selectedSap.name}>{globalStore.selectedSap.id}&apos;s</span> {practice?.toCsVersion.version} upgrade</div>
        <div className="spot-typography__text--tertiary">
          Here are your upgrade schedule details and Summary report. <br/>
          You can also adjust and resend notifications, reschedule or even cancel your upgrade.
        </div>
      </div>
      <dl className='steps-list'>
        <dt>Step 1 - Acknowledgements Reviewed <QualificationIcon pass={upgradeScheduled || operationSuccessful} /></dt>
          <dd>{acknowledgementsLastAcceptedDate} by {practice.acknowledgementsLastAccepted?.name || ''}</dd>
        <dt>Step 2 - Schedule Data and Time <QualificationIcon pass={upgradeScheduled || operationSuccessful} /></dt>
          <dd>{scheduleDate} by {practice.scheduledBy || ''}</dd>
        <dt>Step 3 - Check Qualifications <QualificationIcon pass /></dt>
          <dd>Upgrade - {statistics.upgradeServers} server (Qualified)</dd>
          <dd>Upgrade - {statistics.upgradeWorkstations} workstations (Qualified)</dd>
          <dd>Do Not Upgrade - {statistics.doNotUpgrade} computers</dd>
        <dt>Step 4 - Notifications {upgradeScheduled && <QualificationIcon pass />}</dt>
          <dd>
            <Button
              className='add-more-btn'
              ariaLabel='add-more'
              type='link'
              size='small'
              icon='plus'
              disabled={store.currentContacts.length >= 4}
              onClick={(e: any) => {
                store.addContact();
                e.stopPropagation();
              }}
              cypressData='add-conatect-btn'
            >
              Add more
            </Button>
            <span className='gray-note'>(You may add up to 3 contacts)</span>
            <Grid
              id='notifications-grid'
              columns={colDefs}
              data={currentContacts}
              zebraRows
            />
          </dd>
        <dt>Step 5 - Upgrade Options</dt>
          <dd>
            <RadioButton
              id='skip-offline-workstations'
              className='upgrade-option'
              ariaLabel='skip-offline-workstations'
              checked={practice.skipOfflineWorkstations === true}
              disabled={upgradeScheduled}
              onChange={checked => runInAction(() => practice.skipOfflineWorkstations = checked)}
            >
              Proceed with upgrade if workstations are offline
            </RadioButton>
            <ul>
              <li>Continue with upgrade if any workstations are offline at your chosen upgrade time.</li>
              <li>If the server is offline, the upgrade will not continue.</li>
              <li>A report will be sent to the contacts above indicating which workstations were not upgraded.</li>
            </ul>
          </dd>
          <dd>
            <RadioButton
              id='dont-skip-offline-workstations'
              className='upgrade-option'
              ariaLabel='dont-skip-offline-workstations'
              checked={practice.skipOfflineWorkstations === false}
              disabled={upgradeScheduled}
              onChange={checked => runInAction(() => practice.skipOfflineWorkstations = !checked)}
            >
              Upgrade only if all selected computers are online
            </RadioButton>
            <ul>
              <li>If any computers selected for upgrade are offline at your chosen time, the upgrade will not take place.</li>
              <li>A message will be sent to the contacts above to notify them the upgrade did not proceed.</li>
            </ul>
          </dd>
        {(upgradeScheduled || !operationSuccessful) && practice.pdfUrl && (
          <>
            <dt className='doc-header'>Upgrade Summary Report <QualificationIcon pass={operationSuccessful}></QualificationIcon></dt>
            {!operationSuccessful && (
              <>
                <dd className='doc-error-message'>
                  {upgradeScheduled
                    ? 'A problem occurred when removing the current scheduled upgrade on some of your computers.'
                    : 'A problem occurred when scheduling your upgrade.'}
                </dd>
                <dd className='doc-error-message last'>See the attached reports for details and next steps.</dd>
              </>
            )}
            <dd>
              <a href={practice.pdfUrl} target='_blank' rel='noopener noreferrer' className='doc-link'>
                <img src='/images/pdf_document_icon.svg' className='doc-icon' alt='PDF icon' title='Click to view the report' />
              </a>
            </dd>
            {practice.acknowledgmentsPdfUrl && (
              <>
                <dt className='doc-header'>Acknowledgments Review <QualificationIcon pass={operationSuccessful}></QualificationIcon></dt>
                <dd>
                  <a href={practice.acknowledgmentsPdfUrl} target='_blank' rel='noopener noreferrer' className='doc-link'>
                    <img src='/images/pdf_document_icon.svg' className='doc-icon' alt='PDF icon' title='Acknowledgements Document' />
                  </a>
                </dd>
              </>
            )}
          </>
        )}
      </dl>
      <div className='confirmation_actions'>
        <div>
          {upgradeScheduled ? <>
            <Button
              type='link'
              size='small'
              disabled={buttonsDisabled}
              loading={cancelLoader}
              onClick={() => store.promptCancelUpgrade()}
              cypressData="cancel-btn"
            >
              Cancel Upgrade
            </Button>
          </> : <>
            <Button type="link" size="small" onClick={() => globalStore.showExitUpgradePopup(() => store.exitUpgrade())} cypressData="exit-btn">Exit Upgrade</Button>
            <Button type="link" size="small" onClick={() => navigate(-1)} cypressData="back-btn">Back</Button>
          </>}
        </div>
        <div>
          {upgradeScheduled ? <>
            <Button
              type="secondary"
              size="small"
              disabled={buttonsDisabled}
              loading={notificationLoader}
              onClick={() => store.promptRescheduleUpgrade(<RescheduleUpgradePrompt />)}
            >
              Reschedule Upgrade
            </Button>
            <Button
              type="secondary"
              size="small"
              disabled={buttonsDisabled}
              loading={notificationLoader}
              onClick={() => store.resendNotifications()}
            >
              Resend Notifications
            </Button>
            <Button
              type="primary"
              size="small"
              disabled={buttonsDisabled}
              loading={notificationLoader}
              onClick={() => navigate('/')}
            >
              Dashboard
            </Button>
          </> : <>
            <Button type="secondary" size="small" disabled={buttonsDisabled} loading={saveLoader} onClick={() => store.saveForLater()}>Save for later</Button>
            <Button type="primary" size="small" disabled={buttonsDisabled || !isDefined(practice.skipOfflineWorkstations)} loading={notificationLoader} onClick={() => store.scheduleAndNotify()}>Schedule and Notify</Button>
          </>}
        </div>
      </div>
    </div>
  );
});

export default Confirmation;
