import AbstractStore from '../../store/AbstractStore';
import { action, computed, observable, runInAction } from 'mobx';
import { type CsVersion, type CsVersionValidation, PracticeConfig } from '../../types/practice-types';
import type { GridColDef, SortingObservable } from '../../components/Grid/Grid';
import {
  actionsRenderer,
  contactsRenderer,
  csVersionRenderer,
  dateRenderer,
  MessageCancelUpgrade,
  MessageCancelUpgradeCommunicationError,
  MessageCancelUpgradeError,
  reportRenderer,
  sapIdRenderer,
  statusRenderer,
} from './renderers';
import { GLOBALS_PATH, PRACTICE_PATH } from '../../store/constants';
import React from 'react';
import { isDefined, sortByField } from '../../utils/object-utils';
import dayjs from 'dayjs';
import { regexToSearchWithWildcards } from '../../utils/search-utils';
import { semverLessThan } from '../../utils/semver-utils';

interface Loaders {
  save?: boolean;
  page?: boolean;
  refresh?: boolean;
  maxUpgrades?: boolean;
}

export default class UpgradeManagementStore extends AbstractStore {
  @observable loaders: Loaders = {};

  @observable
  public items: PracticeConfig[] = [];

  @observable
  public csVersions: CsVersion[] = [];

  @observable
  public cancellingItemIndex: number | undefined = undefined;

  @observable
  public maxUpgrades = '0';

  @observable
  public maxUpgradesEditMode = false;

  @observable
  public maxUpgradesCurrent= '0';

  @observable
  public maxUpgradesError= '';

  @observable
  public sorting: SortingObservable<PracticeConfig> = {};

  @observable
  public futureOnly = false;

  @observable
  public searchText = '';

  @observable
  public editItemIndex = -1;

  @observable
  public isNewItem = false;

  @observable
  public currentCsVersionIndex = -1;

  @computed
  public get colDefs(): GridColDef<PracticeConfig>[] {
    return [
      { headerName: 'Upgrade Status', field: 'scheduleUpgradeStatus', renderer: statusRenderer, sortable: true },
      { headerName: 'SAP ID', field: 'sapId', renderer: sapIdRenderer, sortable: true },
      { headerName: 'Practice Name', field: 'practiceName', sortable: true },
      { headerName: 'CS Version', field: 'toCsVersion.version', renderer: csVersionRenderer },
      { headerName: 'Upgrade Date And Time', field: 'scheduleUpgradeTimestamp', renderer: row => dateRenderer(row.scheduleUpgradeTimestamp), sortable: true },
      { headerName: 'Machine Count', field: '', renderer: row => row.successAgentIds?.length, sortable: false },
      { headerName: 'Practice Contacts', field: 'contacts', renderer: contactsRenderer, sortable: false },
      { headerName: 'Last Updated By', field: 'scheduledBy', sortable: true },
      { headerName: 'Last Updated On', field: 'scheduleTimestamp', renderer: row => dateRenderer(row.scheduleTimestamp), sortable: true },
      { headerName: 'Summary Report', field: 'pdfUrl', renderer: reportRenderer, sortable: false },
      { headerName: 'Actions', field: '', renderer: actionsRenderer, sortable: false },
    ];
  }

  @computed
  public get itemsFiltered(): PracticeConfig[] {
    let filtered = [ ...this.items ];
    let editItem: PracticeConfig | undefined = undefined;
    if (this.isNewItem && this.editItemIndex > -1) {
      editItem = filtered[this.editItemIndex];
      filtered.splice(this.editItemIndex, 1);
    }
    if (this.searchText !== '') {
      const regex = regexToSearchWithWildcards(this.searchText);
      filtered = this.items.filter(i => (
        regex.test(i.sapId || '') || regex.test(i.practiceName || '') || regex.test(i.scheduledBy || '')
          || i.contacts?.some(c => regex.test(c.name))
      ));
    }
    if (this.futureOnly) {
      const now = dayjs().valueOf();
      filtered = filtered.filter(i => isDefined(i.scheduleUpgradeTimestamp) && i.scheduleUpgradeTimestamp > now);
    }
    if (this.sorting.field) {
      filtered = sortByField(filtered, this.sorting.field);
      if (this.sorting.order === 'DESC') {
        filtered = filtered.reverse();
      }
    }
    return editItem ? [editItem, ...filtered] : filtered;
  }


  @computed
  public get cancelling() {
    return this.cancellingItemIndex !== undefined;
  }

  @action
  public async init(): Promise<void> {
    runInAction(() => {
      this.loaders = {
        page: true,
      };
      this.sorting = { field: 'sapId', order: 'ASC' };
      this.futureOnly = false;
      this.searchText = '';
      this.cancellingItemIndex = undefined;
      this.maxUpgradesEditMode = false;
      this.editItemIndex = -1;
      this.isNewItem = false;
    });
    const items = await this.root.apiStore.getAllPractices();
    const maxUpdatesPerDay = await this.root.apiStore.getGlobalValue<number>('UPGRADES_PER_DAY_MAXIMUM_NUMBER');
    const csVersions = await this.root.apiStore.getCsVersions();
    runInAction(() => {
      this.items = items;
      this.csVersions = csVersions.sort((v1, v2) => {
        if (v1.default) return -1;
        if (v2.default) return 1;
        return semverLessThan(v1.version, v2.version) ? 1 : -1;
      });
      this.maxUpgrades = String(maxUpdatesPerDay || 0);
      this.loaders.page = false;
    });
  }

  @action
  public async refresh(): Promise<void> {
    runInAction(() => {
      this.loaders.refresh = true;
      this.items = [];
    });
    const items = await this.root.apiStore.getAllPractices();
    runInAction(() => {
      this.items = items;
      this.loaders.refresh = false;
    });
  }

  @action
  public promptCancelUpgrade(index: number): void {
    this.root.globalStore.showOkCancelPopup(
      'Cancel Upgrade',
      React.createElement(MessageCancelUpgrade),
      'Confirm',
      'Cancel',
      () => this.cancelUpgrade(index),
      undefined,
      false,
      false,
    );
  }

  @action
  public startEditingMaxUpgrades() {
    this.maxUpgradesCurrent = this.maxUpgrades;
    this.maxUpgradesEditMode = true;
    this.maxUpgradesError = '';
  }

  @action
  public cancelEditingMaxUpgrades() {
    this.maxUpgradesEditMode = false;
    this.maxUpgradesError = '';
  }

  @action
  public changeMaxUpgrades(value: string) {
    if (/^\d*$/.test(value) && value.length <= 7) this.maxUpgradesCurrent = value;
  }

  @action
  public async updateMaxUpgrades(): Promise<void> {
    const maxUpgradesNumber = parseInt(this.maxUpgradesCurrent);
    if (maxUpgradesNumber === 0) {
      runInAction(() => this.maxUpgradesError = 'Cannot be 0');
    } else if (maxUpgradesNumber === parseInt(this.maxUpgrades)) {
      runInAction(() => this.maxUpgradesEditMode = false);
    } else {
      runInAction(() => this.loaders.maxUpgrades = true);
      await this.root.apiStore.backendPost(GLOBALS_PATH, {
        key: 'UPGRADES_PER_DAY_MAXIMUM_NUMBER',
        value: maxUpgradesNumber,
      });
      runInAction(() => {
        this.maxUpgrades = this.maxUpgradesCurrent;
        this.maxUpgradesEditMode = false;
        this.loaders.maxUpgrades = false;
      });
    }
  }

  @action
  private async cancelUpgrade(index: number): Promise<void> {
    runInAction(() => {
      this.root.globalStore.globalModalProps.show = false;
      this.cancellingItemIndex = index;
    });
    void this.audit('UPGRADE_MANAGEMENT', 'UPGRADE_MANAGEMENT_PAGE_CANCEL_UPGRADE');
    try {
      const practice = await this.root.apiStore.backendPost(PRACTICE_PATH, {
        action: 'CANCEL_UPGRADE',
        sapId: this.itemsFiltered[index].sapId,
      });
      runInAction(() => {
        const itemIndex = this.items.findIndex(i => i.sapId === this.itemsFiltered[index].sapId);
        if (itemIndex > -1) {
          this.items[itemIndex] = practice;
        }
      });
    } catch (err: any) {
      this.root.globalStore.showOkPopup(
        'Connection error',
        'Unable to connect to Kaseya, please try again later',
      );
    }
    if (this.itemsFiltered[index].scheduleError === 'Kaseya') {
      this.root.globalStore.showOkPopup(
        'Error Cancelling',
        React.createElement(MessageCancelUpgradeError),
      );
    }
    if (this.itemsFiltered[index].scheduleError === 'Communication') {
      this.root.globalStore.showOkPopup(
        'Communication error',
        React.createElement(MessageCancelUpgradeCommunicationError),
      );
    }
    runInAction(() => this.cancellingItemIndex = undefined);
  }

  @action
  public addItem() {
    this.currentCsVersionIndex = this.csVersions.findIndex(v => v.default);
    this.items = [{
      sapId: '',
      practiceName: '',
      toCsVersion: this.csVersions[this.currentCsVersionIndex],
    }, ...this.items];
    this.editItemIndex = 0;
    this.isNewItem = true;
  }

  @action
  public startEdit(index: number) {
    this.editItemIndex = index;
    const item = this.items.find(i => i.sapId === this.itemsFiltered[index].sapId);
    this.currentCsVersionIndex = this.csVersions.findIndex(v => v.version === item?.toCsVersion.version);
  }

  @action
  public async saveItem(): Promise<void> {
    runInAction(() => {
      this.loaders.save = true;
    });
    try {
      const csVersionItem = this.csVersions[this.currentCsVersionIndex];
      await this.root.apiStore.backendPost<CsVersionValidation>(PRACTICE_PATH, {
        action: this.isNewItem ? 'CREATE' : 'UPDATE_CS_VERSION',
        sapId: this.itemsFiltered[this.editItemIndex].sapId,
        csVersion: csVersionItem.version,
      });
      if (this.isNewItem) {
        await this.root.apiStore.fetchCurrentUserDetails();
      }
      runInAction(() => {
        const item = this.items.find(i => i.sapId === this.itemsFiltered[this.editItemIndex].sapId);
        item!.toCsVersion = csVersionItem;
        this.isNewItem = false;
        this.editItemIndex = -1;
      });
    } catch (e: any) {
      if (e.message?.includes('already exists')) {
        this.root.globalStore.showOkPopup(
          'Record Exists For Practice',
          'This practice has upgraded with MyCornerstone before. Please edit the existing record.',
        );
      }
    } finally {
      runInAction(() => {
        this.loaders.save = false;
      });
    }
  }

  @action
  public cancelEditing() {
    if (this.isNewItem) {
      this.items.splice(0, 1);
    }
    this.isNewItem = false;
    this.editItemIndex = -1;
  }
}
