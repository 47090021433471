import React, { useState } from 'react';
import './CheckQualifications.scss';
import Checkbox from '../../components/Checkbox/Checkbox';
import Pill from '../../components/Pill/Pill';
import SpotIcon from '../../components/SpotIcon/SpotIcon';
import { runInAction } from 'mobx';
import Spinner from '../../components/Spinner/Spinner';
import Popover from '../../components/Popover/Popover';
import Button from '../../components/Button/Button';
import TableCellTextBox from '../../components/TableCellTextBox/TableCellTextBox';
import { observer } from 'mobx-react';
import { useStore } from '../../store/hooks';
import { formatMBytes, isDefined } from '../../utils/object-utils';
import QualificationIcon from '../../components/QualificationIcon/QualificationIcon';
import { AgentData, AgentQualifications, AuditStatus, UpgradeStatus } from '../../types/practice-types';
import { useCheckSinglePermission } from '../../security/security-hook';
import { CHECK_QUALIFICATIONS_QUALIFICATION_OVERRIDE, PERMISSION_VIEW } from '../../types/users-types';

interface CommonRendererProps {
  row: AgentData;
  index: number;
}

export const allRenderer = (row: AgentData, index: number, tableId: string) => (
  <Checkbox
    id={`${tableId}-all_${index}`}
    ariaLabel="select-agent-checkbox"
    checked={row.checked}
    disabled={row.auditStatus === AuditStatus.PENDING}
    onChange={() => runInAction(() => row.checked = !row.checked)}
    cypressData={`select-${row.agentId}-cb`}
  />
);

const StatusRenderer = observer((props: CommonRendererProps) => {
  switch (props.row.status) {
    case UpgradeStatus.UPGRADE: return <>Upgrade</>;
    case UpgradeStatus.DO_NOT_UPGRADE: return <>Do Not Upgrade</>;
    case UpgradeStatus.UPGRADE_MANUALLY_APPROVED: return <>Upgrade (Manually Approved)</>;
  }
  return null;
});

export const LabelRenderer = observer((props: CommonRendererProps) => {
  const store = useStore().checkQualificationsStore;
  const { row, index } = props;

  const clickHandler = (e: any) => {
    // on double click only
    if (store.editLabelIndex === null && e.detail === 2) {
      runInAction(() => {
        if (row.label === undefined) row.label = '';
        store.editLabelIndex = index;
      });
    }
  };

  return index === store.editLabelIndex
    ? <TableCellTextBox
      id={`agent-label-${row.agentId}`}
      ariaLabel="label-editor"
      placeholder='Add a nickname'
      initialValue={row.label}
      maxLength={15}
      disabled={store.loaders.label}
      classNameForCancel="cancel-editing"
      onSave={v => store.updateLabel(v)}
      onCancel={() => runInAction(() => store.editLabelIndex = null)}
      cypressData={`label-${row.agentId}-input`}
    />
    : (
      <span role="label-display" className={`label-cell ${row.label ? '' : 'gray-span'}`} onClick={e => clickHandler(e)} data-cy={`label-${row.agentId}-span`}>
        {row.label || 'Add a nickname' }
      </span>
    );
});

const AuditRenderer = observer((props: CommonRendererProps) => {
  const { row } = props;
  const store = useStore().checkQualificationsStore;
  if (row.auditLoading) return <Spinner size={24}/>;

  if (store.duplicateAgentsToUpgradeNames.includes(row.name) && row.auditStatus !== AuditStatus.PENDING) return (
    <Button
      style={{ padding: 0 }}
      type='link'
      size='small'
      title='Click to refresh the computer name'
      onClick={() => store.initiateCheck(row)}
      cypressData={`refresh-name-${row.agentId}-btn`}
    >
      Refresh Name
    </Button>
  );

  switch (row.auditStatus) {
    case AuditStatus.QUALIFIED: return <Pill type="positive" small outline icon="checkmark">Qualified</Pill>;
    case AuditStatus.UNQUALIFIED: return <Pill type="negative" small outline icon="cancel">Unqualified</Pill>;
    case AuditStatus.REQUIRED: return (
      <Button
        style={{ padding: 0 }}
        type='link'
        size='small'
        title='Click to check if this computer meets upgrade requirements'
        onClick={() => store.initiateCheck(row)}
        cypressData={`initiate-check-${row.agentId}-btn`}
      >
        Initiate Check
      </Button>
    );
    case AuditStatus.ERROR: {
      const warningTooltip = `
        1. Confirm computer is powered on and connected to the internet
        2. Restart the computer
        3. Check for any pending Windows updates and install if present
        4. Contact Cornerstone Customer Support if problems persist
        `.replaceAll(/\r?\n\s+/g, '\n');
      return <Pill type="warning" small outline interactive icon="alert-notification" title={warningTooltip}>Check Failed</Pill>;
    }
    case AuditStatus.PENDING: return store.loaders.pending ? <Spinner size={24} /> : <>Processing...</>;
    default: return null;
  }
});

interface ActionsType extends CommonRendererProps {
  expandedRowIds?: string[];
  action: (row: any, index: any) => void;
}

const ExpandCollapseRenderer = observer((props: ActionsType) => {
  const { row, action, expandedRowIds, index } = props;
  const iconPath = expandedRowIds && expandedRowIds.includes(row.agentId) ? 'caret-up' : 'caret-down';
  const iconHint = expandedRowIds && expandedRowIds.includes(row.agentId) ? 'collapse' : 'expand';
  return (
    <div className="actions-cell">
      <SpotIcon className="expand-collapse-icon" path={iconPath} ariaLabel={`agent-${iconPath}`} title={iconHint} onClick={() => action(row, index)} cypressData={`expand-${row.agentId}-row`} />
    </div>
  );
});

const nonOverridableQualificationsFields: (keyof AgentQualifications)[] = ['csVersion', 'freeSpace', 'os', 'connectivity', 'powershell'];

export const hasOverridableQualificationIssue  = (agent: AgentData): boolean =>
  agent.auditStatus === AuditStatus.UNQUALIFIED
    && isDefined(agent.qualifications)
    && nonOverridableQualificationsFields.every(field => agent.qualifications?.[field])
    && (Object.keys(agent.qualifications) as (keyof AgentQualifications)[])
      .filter(key => !agent.qualifications?.[key])
      .some(field => !nonOverridableQualificationsFields.includes(field));

const overrideAvailable = (agent: AgentData): boolean =>
  agent.auditStatus === AuditStatus.UNQUALIFIED && agent.status !== UpgradeStatus.UPGRADE_MANUALLY_APPROVED
    && isDefined(agent.qualifications)
    && nonOverridableQualificationsFields.every(field => agent.qualifications?.[field]);

const ActionsRenderer = observer((props: CommonRendererProps) => {
  const { row, index } = props;
  const store = useStore().checkQualificationsStore;
  const [popoverShown, setPopoverShown] = useState<boolean>(false);
  const isAdminOverride = useCheckSinglePermission(CHECK_QUALIFICATIONS_QUALIFICATION_OVERRIDE, PERMISSION_VIEW);

  const upgradeItemDisabled = row.type === 'Server' && row.status === UpgradeStatus.UPGRADE && store.serversToUpgrade <= 1;

  return (
    <div className="actions-cell">
      <Popover
        id={`agents-actions-${row.agentId}`}
        position="bottom-left"
        inset="small"
        element={<SpotIcon ariaLabel="agent-ellipsis" path="more-2" title="Click the action menu to access computer specific options."/>}
        shown={popoverShown}
        setShown={setPopoverShown}
        cypressData={`actions-${row.agentId}-popover`}
      >
        <Button type="link" size="small" onClick={() => runInAction(() => { store.editLabelIndex = index; setPopoverShown(false);})} cypressData={`edit-label-${row.agentId}-link`}>
          Add/Edit Nickname
        </Button>
        <Button
          type="link"
          size="small"
          disabled={row.auditStatus === AuditStatus.PENDING || row.rowLoading || row.auditLoading}
          onClick={() => { void store.initiateCheck(row); setPopoverShown(false);}}
          cypressData={`initiate-check-${row.agentId}-link`}
        >
          Initiate Check
        </Button>
        <Button
          type="link"
          size="small"
          disabled={row.auditStatus === AuditStatus.PENDING || row.rowLoading || row.auditLoading}
          onClick={() => { void store.sendIdentificationAlert(index); setPopoverShown(false); }}
          cypressData={`find-me-${row.agentId}-link`}
        >
          Find me
        </Button>
        <Button
          type="link"
          size="small"
          disabled={store.loaders.status || upgradeItemDisabled}
          onClick={() => { store.switchStatus(index); setPopoverShown(false); }}
          cypressData={`status-${row.status}-${row.agentId}-link`}
        >
          {row.status === UpgradeStatus.DO_NOT_UPGRADE ? 'Upgrade' : 'Do Not Upgrade'}
        </Button>
        <Button
          type="link"
          size="small"
          disabled={store.loaders.status || !overrideAvailable(row)}
          onClick={() => store.switchStatusToOverride(index).then(() => setPopoverShown(false))}
          cypressData={`qualification-override-${row.agentId}-link`}
        >
          {isAdminOverride ? 'Qualification Override' : 'Override Failed Qualification'}
        </Button>
      </Popover>
    </div>
  );
});

export const SubRowRenderer = (row: AgentData) => (
  <div className="subrow-qualifications">
    <div className="subrow-title">
      <div className="subrow-header">QUALIFICATIONS</div>
      <a onClick={() => window.open('https://www.idexx.com/files/cornerstone-hw-os-guidelines.pdf')}
         className="spot-link spot-link--small"> Hardware & System guidelines </a>
    </div>
    <div className="subrow-content">
      <div className="section-title">
        CORNERSTONE REQUIREMENTS
      </div>
      <div className="flex-box">
        <ul>
          <li><QualificationIcon pass={row.qualifications?.os} /> Operating System: <strong>{row.os}</strong></li>
          <li><QualificationIcon pass={row.qualifications?.processor} /> Processor: <strong>{row.processor}</strong></li>
          <li><QualificationIcon pass={row.qualifications?.memory} /> Memory: <strong>{formatMBytes(row.memoryMb)}</strong></li>
        </ul>
        <ul>
          <li><QualificationIcon pass={row.qualifications?.csVersion} /> Cornerstone: <strong>{row.csVersion}</strong></li>
          <li><QualificationIcon pass={row.qualifications?.csDrive} /> Cornerstone Drive: <strong>{row.csDrive}</strong></li>
        </ul>
        <ul>
          <li>
            <QualificationIcon pass={row.qualifications?.diskSize} /> Disk Capacity: <strong>{formatMBytes(row.diskSizeMb)}</strong><SpotIcon className="info-icon" path="info-2" title="This is an approximate amount of usable space and may not match the true drive size" />
          </li>
          <li><QualificationIcon pass={row.qualifications?.freeSpace} /> Free Disk Space: <strong>{formatMBytes(row.freeSpaceMb)}</strong></li>
        </ul>
      </div>
      <div className="section-title">
        UPGRADE AUTOMATION REQUIREMENTS
      </div>
      <div className="flex-box">
        <ul>
          <li>
            <QualificationIcon pass={row.qualifications?.connectivity} /> Upgrade System Connectivity <SpotIcon className='info-icon' path='info-2' title='Ability to download upgrade files' />
          </li>
        </ul>
        <ul>
          <li>
            <QualificationIcon pass={row.qualifications?.powershell} /> Powershell Script Execution <SpotIcon className='info-icon' path='info-2' title='Ability to execute upgrade PowerShell scripts' />
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export const FailedQualificationsModalContent = ({ qualifications }: { qualifications: AgentQualifications }) => (
  <div className="failed-qualifications-modal-content">
    <p>
      This computer is not qualified for automated upgrade. See below for an explanation of what MyCornerstone is evaluating and recommendations for resolution.
    </p>
    <ul>
      {!qualifications.os && <li>
        <strong>Operating System</strong> - Your computer&apos;s operating system is not supported. Consider replacing the computer, or if possible upgrading to a supported version of Windows
        as seen in the Hardware & System guidelines.
      </li>}
      {!qualifications.processor && <li>
        <strong>Processor</strong> - Your computer&apos;s processor is not supported. IDEXX recommends an Intel Core CPU. MyCornerstone also considers
        certain AMD processor families as qualified which are not included on published IDEXX recommendations. Consider replacing the computer.
      </li>}
      {!qualifications.memory && <li>
        <strong>Memory</strong> - Your computer does not have sufficient memory (RAM) installed. Consider replacing the computer,
        or if possible installing additional memory to align with requirements found in the Hardware & System guidelines.
      </li>}
      {!qualifications.csVersion && <li>
        <strong>Cornerstone</strong> - MyCornerstone cannot be used to upgrade from your current version of Cornerstone. Upgrade manually to at least Cornerstone 9.1
        before scheduling an upgrade with MyCornerstone.
      </li>}
      {!qualifications.diskSize && <li>
        <strong>Disk Capacity</strong> - Your computer does not meet IDEXX&apos;s recommendations for hard drive capacity.
        MyCornerstone allows for some discrepancy between manufacturer&apos;s published disk size and true disk capacity. Consider replacing the computer, or if possible installing a hard drive with greater capacity which aligns with requirements found in the Hardware & System guidelines.
      </li>}
      {!qualifications.freeSpace && <li>
        <strong>Free Disk Space</strong> - Your computer does not have enough free space to support the upgrade. Workstations require at least 4GB of free space,
        while servers require 4GB plus additional space to make copies of critical database and log files. Note that for servers,
        regardless of which drive Cornerstone is installed on, these backups are always made on the C:\ drive. Consider deleting unnecessary files to create more free space or installing a hard drive with greater capacity.
      </li>}
      {!qualifications.connectivity && <li>
        <strong>Upgrade Systems Connectivity</strong> - Your computer cannot connect to the remote systems used to manage the upgrade.
        Ensure that the computer is connected to the internet, and review antivirus and firewall configuration.
      </li>}
      {!qualifications.powershell && <li>
        <strong>Powershell</strong> - MyCornerstone cannot use Windows PowerShell to perform upgrade actions.
        Ensure that PowerShell is installed, up to date, and that there are no local policies which may prevent scripts from being executed by the SYSTEM user.
      </li>}
    </ul>
  </div>
);

export const MessageMultipleServersDetected = () => <>It looks like more than one computer was configured as a Cornerstone server recently.<br />Please contact Cornerstone support at <strong>1-800-695-2877</strong> to identify your true server before continuing.</>;

export const MessageNoServerDetected = () => <>It looks like we cannot identify your Cornerstone Server.<br />Please contact Cornerstone support at <strong>1-800-695-2877</strong> to identify your server before continuing.</>;

export const MessageRemoveOverride = () => <>The manual approval to upgrade this computer will be removed.<br /> Do you want to continue?</>;

export const MessageNeedHelp = () =>
  <ul>
    <li>Step 1 - Create nicknames for easy machine recognition. Use <strong>Find me</strong> to identify machines.</li>
    <li>Step 2 - Select computers to include with automatic upgrade.</li>
    <li>Step 3 - Initiate Check and review qualifications</li>
    <li>Step 4 - Confirm Schedule</li>
  </ul>;

// eslint-disable-next-line react/display-name
export const MessageSendInstructionsSent = (userEmail: string) => () => <>An email with the download link and installation instructions has been sent to <strong>{userEmail}</strong></>;

export const MessageSendInstructionsFailed = () => <>
  <p>We are unable to send the email at this time.</p>
  <p>Please use the download link and on screen instructions or try again later.</p>
</>;

export const MessageAdditionalSetupRequired = () => <>
  <p>IDEXX needs to make some changes to your practice&apos;s account before you can schedule your upgrade.</p>
  <p>Please allow up to 4 business days for this process to complete and try again.</p>
</>;

// eslint-disable-next-line react/display-name
export const MessageInsufficientDiskCSpace = (serverName: any, requiredFreeSpace: any) => () => <>
  <p>During the upgrade process, Cornerstone makes backups of critical files to <strong>{serverName}</strong>&apos;s C:\ drive.</p>
  <p>In order to successfully complete the upgrade, you must have at least <strong>{requiredFreeSpace}</strong> of disk space available.</p>
</>;

export const MessageDuplicateComputerNames = () => <>
  <p>More than one computer you want to upgrade share the same name.</p>
  <p>Make changes to computer names at your practice and then perform another qualification check to update MyCornerstone.</p>
</>;

export const MessageOverrideFailedQualifications = () => <>
  <p>This computer does not meet IDEXX&apos;s Hardware &amp; Operating System guidelines for the latest Cornerstone version. If upgraded, performance of Cornerstone may be adversely affected.</p>
  <p>Do you wish to continue?</p>
</>;

export const expandCollapseRenderer = (action: (row: AgentData) => void) => {
  const expandCollapseRendererWrapper = (row: AgentData, index: number, _id: string, expandedRowIds: string[] | undefined) => <ExpandCollapseRenderer row={row} index={index} expandedRowIds={expandedRowIds} action={action} />;
  return expandCollapseRendererWrapper;
};

export const actionsRenderer = (row: AgentData, index: any) => <ActionsRenderer row={row} index={index} />;

export const labelRenderer = (row: AgentData, index: any) => <LabelRenderer row={row} index={index} />;

export const auditRenderer = (row: AgentData, index: any) => <AuditRenderer row={row} index={index} />;

export const statusRenderer = (row: AgentData, index: any) => <StatusRenderer row={row} index={index} />;
