import AbstractStore from '../../store/AbstractStore';
import { action, observable, runInAction } from 'mobx';
import type { CsVersion, CsVersionValidation } from '../../types/practice-types';
import { semverLessThan } from '../../utils/semver-utils';
import { CS_VERSIONS_PATH } from '../../store/constants';

interface Loaders {
  page?: boolean;
  save?: boolean;
}

export default class UpgradeVersionManagementStore extends AbstractStore {
  @observable loaders: Loaders = {};

  @observable versions: CsVersion[] = [];

  @observable selectedVersionIndex = -1;

  @observable spinnerVersionIndex = -1;

  @observable editMode = false;

  @observable isNewVersion = false;

  // @ts-ignore
  @observable currentVersion: CsVersion = {};

  @observable validation: CsVersionValidation = {};

  @action
  public async init(): Promise<void> {
    if (this.versions.length > 0) return;
    runInAction(() => this.loaders.page = true);
    const csVersions = await this.root.apiStore.getCsVersions();
    runInAction(() => {
      this.versions = csVersions;
      this.sortVersions();
      this.loaders.page = false;
    });
  }

  @action
  public changeSelectedVersion(index: number): void {
    if (!this.editMode) {
      this.selectedVersionIndex = this.selectedVersionIndex === index ? -1 : index;
    }
  }

  @action
  public addVersion(): void {
    this.versions.push({
      version: '',
      installerName: '',
      installerArgs: '',
      installerSize: 0,
      minimalUpgradeVersion: '',
      cpuPattern: '',
      osPattern: '',
      serverRam: 0,
      serverDiskSize: 0,
      workstationDiskSize: 0,
      workstationRam: 0,
      qualificationAPs: '',
      default: false,
      isDeletable: true,
    });
    this.startEditingLastItem();
  }

  @action
  public startEdit(index: number): void {
    this.currentVersion = { ...this.versions[index] };
    this.validation = {};
    this.selectedVersionIndex = index;
    this.editMode = true;
    this.isNewVersion = false;
  }

  @action
  public cloneAndEdit(index: number): void {
    this.versions.push({ ...this.versions[index], default: false, isDeletable: true });
    this.startEditingLastItem();
  }

  @action
  public async makeDefault(index: number): Promise<void> {
    await this.backendActionWithVersionIndex(index, async () => {
      await this.root.apiStore.backendPost(CS_VERSIONS_PATH, {
        action: 'MAKE_DEFAULT',
        version: this.versions[index].version,
      });
      runInAction(() => {
        this.versions.forEach((version, idx) => version.default = index === idx);
        this.sortVersions();
      });
    });
  }

  @action
  public async showDeleteModal(index: number): Promise<void> {
    this.root.globalStore.showOkCancelPopup(
      'Confirm version deletion',
      `Are you sure you want to delete version ${this.versions[index].version}? This action cannot be undone.`,
      'OK',
      'Cancel',
      () => this.deleteVersion(index),
    );
  }

  @action
  public async deleteVersion(index: number): Promise<void> {
    await this.backendActionWithVersionIndex(index, async () => {
      await this.root.apiStore.backendPost(CS_VERSIONS_PATH, {
        action: 'DELETE',
        version: this.versions[index].version,
      });
      runInAction(() => {
        this.versions.splice(index, 1);
        if (this.selectedVersionIndex >= index) {
          this.selectedVersionIndex = this.selectedVersionIndex === index ? -1 : this.selectedVersionIndex - 1;
        }
      });
    });
  }

  @action
  public cancelEditing(): void {
    if (this.isNewVersion) {
      this.versions.pop();
      this.selectedVersionIndex = -1;
      this.isNewVersion = false;
    }
    this.spinnerVersionIndex = -1;
    this.editMode = false;
  }

  @action
  public async saveVersion(): Promise<void> {
    await this.backendActionWithVersionIndex(this.selectedVersionIndex, async () => {
      const response = await this.root.apiStore.backendPost<CsVersionValidation>(CS_VERSIONS_PATH, {
        action: this.isNewVersion ? 'CREATE' : 'UPDATE',
        ...this.currentVersion,
      });
      runInAction(() => {
        this.validation = response;
        if (Object.keys(response).length === 0) {
          this.versions[this.selectedVersionIndex] = this.currentVersion;
          this.editMode = false;
          this.sortVersions();
        }
      });
    });
  }

  @action
  public close(): void {
    this.cancelEditing();
  }

  @action
  private sortVersions(): void {
    const versionValue = this.selectedVersionIndex < 0 ? undefined : this.versions[this.selectedVersionIndex].version;
    this.versions = this.versions.sort((v1, v2) => {
      if (v1.default) return -1;
      if (v2.default) return 1;
      return semverLessThan(v1.version, v2.version) ? -1 : 1;
    });
    this.selectedVersionIndex = this.versions.findIndex(v => v.version === versionValue);
  }

  @action
  private startEditingLastItem(): void {
    this.selectedVersionIndex = this.versions.length - 1;
    this.currentVersion = this.versions[this.selectedVersionIndex];
    this.validation = {};
    this.editMode = true;
    this.isNewVersion = true;
  }

  private async backendActionWithVersionIndex(index: number, fn: () => Promise<void>): Promise<void> {
    runInAction(() => {
      this.loaders.save = true;
      this.spinnerVersionIndex = index;
    });
    try {
      await fn();
    } finally {
      runInAction(() => {
        this.loaders.save = false;
        this.spinnerVersionIndex = -1;
      });
    }
  }
}
